<template lang="html">
  <div class="">
    <div class="main" >
      Bra jobbat. Ett email med kontaktförfrågan har skickats.<br />
      <!-- Bra jobbat. Ett email med säljförfrågan har skickats.<br /> -->
      Vi kommer höra av oss.
    </div>


  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>

@media (min-width: 450px) {

  .main{
    width: 98%;
    margin-Left: 1%;
    margin-Top: 7%;
    font-Size:35px
  }


}

@media (max-width: 450px) {

 .main{
   width: 98%;
   margin-Left: 1%;
   margin-Top: 2%
 }

}


</style>
