import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    current_user:{"user_id":0,"current_user":{},"lan_object":{"lan_id":0,"lan":'', "lan_chosen":false,"lan_filter_start":false},"current_city":"", "message":"", "token":"" ,"username":"", "company_id":0}
  },
  plugins:[createPersistedState()],
  mutations: {
    set_current_user(state, user ){
      state.current_user = user
      window.localStorage.setItem("current_user", user)
    },
    set_city_in_lan(state, city){
      state.current_city = city
    },
    set_lan(state, object){
      state.lan_object = object
      window.localStorage.setItem("lan_object", object)
    },
    set_category_explore_page_general_req_contact_form(state, object){
      state.category_object = object
    }

  },
  actions: {
    set_category_explore_page_general_req_contact_form_action(state,object){
        state.commit('set_category_explore_page_general_req_contact_form',object)
    }


  },
  modules: {
  }
})
