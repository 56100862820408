<template lang="html">
<div :style="{ color: 'black' }">
  <div class="open_gear_box_outside_sent" @click="close_leads_sent()" v-if="this.leads_sent_general">
  </div>
  <div class="open_gear_box_outside_loading" v-if="this.loading" >
  </div>
  <div  class="loading"  v-if="this.loading" >
    <contactFormRequestSending/>
  </div>


  <div v-if="this.leads_sent_general"   class="leads_sent"  >
    <div @click="close_leads_sent()"
      class="leads_sent_button">
       X
    </div>
    <contactFormRequestSent/>
  </div>

 <!-- v-if="contact_requested && this.from_page == 'nav' " -->
    <!-- TODO this.w > 450 && this.from_page == 'home' -->
  <div :class="[ contact_requested  && this.from_page == 'nav'
              ? 'main_from_page_home_large_nav'
              : 'main_from_page_home_large_home']" >

    <!-- <div    v-if="contact_requested && this.from_page == 'home' "
            class="main_from_page_home_large_home"   > -->

          <div class="open_box_x_icon_main"  >
            <b-icon icon="x-lg" @click="open_contact_requested()"></b-icon>
          </div>

      <div class="" >
        <contactformheadertextMainTitle/>

        <div :style="{height:'100%', marginTop:'0%'}" v-if="this.page_nr==1">
            <contactformheadertextjagVillReparera />


            <div class="main_comp">
              <label class="label_size" for="name_input"  >Vilken län bor du i<span :style="{ color: 'red' }"  >*</span ></label    >
              <input    type="text"  v-on:keyup="search_lan_func(lan_name)"   name="name_input"   maxlength="500"  placeholder="Välj län?"  class="input_field"  v-model="lan_name"  />
            </div>
            <div v-if= "this.lan_opened"
            :style="{height:'100px',border:0, position:'absolute',borderWidth:'0',
            boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',overflowY:'auto',
            borderRadius:'8px',marginTop:'1%',
            opacity:'100%',zIndex:13, backgroundColor:'white',width:'35%', marginLeft:'35%'}"  >
            <div  v-for="(lan,idx) in filtered_lan_list" :style="{marginLeft:'4%'}"
                 :key="idx" @click="choose_lan_func(lan.id,lan.lan)" >
              <div :style="{height:'27px',fontSize:'17px', display:'flex',marginTop:'1%'}" >
                <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
                  {{lan.lan}}
                </div>
              </div>
             </div>
            </div>


            <div class="main_comp">
              <label class="label_size" for="name_input" >Vilken stad/kommun bor du i<span :style="{ color: 'red' }">*</span></label  >
              <input
                type="text"
                name="name_input"
                maxlength="500"
                placeholder="Vilken stad bor du i?"
                class="input_field"
                v-model="lead_city"
              />
            </div>


            <contactformTitleTellProblem/>


            <div class="main_comp_valj_produkt">
              <label class="label_size" for="name_input" >Välj produkt<span :style="{ color: 'red' }">*</span></label    >
              <input
                type="text"
                v-on:keyup="get_company_search(search_item)"
                name="name_input"
                maxlength="500"
                placeholder="Välj produkt/kategori"
                class="input_field"
                v-model="search_item"
              />

              <b-icon
                class="open_box_x_icon_search"
                icon="x-lg"
                @click="open_search()"
                v-if="this.search_item_flag == 1"
              />
              <div
                v-if="this.search_item_flag == 1"
                :style="{height:'200px',border:0, position:'absolute',borderWidth:'0',
                boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',overflowY:'auto',
                borderRadius:'8px',marginTop:'1%',
                opacity:'100%',zIndex:13, backgroundColor:'white',width:'40%', marginLeft:'30%'}"  >

                <div :style="{ height: '200px', width: '100%', overflowY: 'auto' }">

                  <div
                    v-for="(search, idx) in search_list"
                    :key="idx"
                    :style="{
                      marginLeft: '1%',
                      textAlign: 'left',
                      width: '99%',
                      height: '27px',
                      fontWeight: 'bold',
                      marginTop: '1%',
                    }"
                  >
                    <div
                      :style="{
                        width: '100%',
                        height: '27px',
                        display: 'flex',
                        marginTop: '1%',
                      }"
                      @click="choose_category(search.id, search.category)"
                    >
                      <div
                        :style="{
                          marginLeft: '1%',
                          marginTop: '1%',
                          width: '100%',
                          fontSize: '17px',
                          lineHeight: '13px',
                          fontWeight: 500,
                          height: '35px',
                          color: 'black',
                        }"
                      >
                        {{ search.category }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="main_comp_finns_kategori">
              <label class="label_size" for="name_input"  >
                Finns inte det du vill laga i listan?<br>
                Skriv in den här
                </label    >
              <input
                type="text"
                name="name_input"
                maxlength="500"
                placeholder="Kategori av föremål"
                class="input_field"
                v-model="category_suggestion"
              />
            </div>


            <div class="main_comp">
              <label class="label_size" for="name_input" >Vad är du villig att betala (prisintervall)?<span :style="{ color: 'red' }">*</span></label    >
              <input  type="text"   name="name_input"   maxlength="500"    placeholder="Vad har du för prisintervall"
              class="input_field"
                v-model="price_interval"   />
            </div>


            <div class="main_comp">
              <div class="label_size_descr">Beskriv det du vill laga och information om den (ex märke, modell mm)</div>
              <textarea
                name="name"
                v-model="description"
                rows="8"
                :style="{ height: '80px', resize: 'none', width: '85%' }"
                cols="80"
              ></textarea>
            </div>

              <button type="button" name="button" class="next_page" @click="next_page()">Nästa</button>

          </div>

          <div class="" v-if="this.page_nr==2">

            <contactFormUploadImagesHeader/>

            <div class="main_comp_home">
              <multiple-image-upload   v-model="imagesToUpload"   />
            </div>

            <contactFormContactInfo/>

            <div class="main_comp">
              <label class="label_size" for="name_input"
                >Namn<span :style="{ color: 'red' }">*</span></label    >
              <input
                type="text"
                name="name_input"
                maxlength="500"
                placeholder="Fyll i ditt namn"
                class="input_field"
                v-model="lead_name"
              />
            </div>


            <div class="main_comp">
              <label class="label_size" for="name_input">
                Kontaktinformation
                <span :style="{ color: 'red' }">*</span></label>
              <input
                type="text"
                name="name_input"
                maxlength="500"
                placeholder="eg emailaddress"
                class="input_field"
                v-model="lead_contact_info"
              />
            </div>

            <contactFormConsent/>

            <div :style="{width:'92%',height:'30px',marginTop:'1%',marginLeft:'7%',display:'flex'}">
              <input class="checkbox" :style="{height:'20px', width:'20px'}" type="checkbox" name="" value="" v-model="check">
              <div :style="{width:'88%',marginLeft:'4%'}">
                Jag förstår att de kontaktuppgifter jag skriver här delas med reparatörer i det område jag har uppgett.
                <!-- Jag förstår att de kontaktuppgifter jag skriver här delas med Enjord. -->
              </div>
            </div>

            <contactformOurReplyPeriodInfo/>

            <div :style="{ height: '50px', display: 'flex',marginTop:'2%',marginLeft:'3%' ,textAlign: 'right' }">
              <button type="button" name="button" class="back_page" @click="back_page()">Tillbaka</button>

              <div :style="{ width: '80%' }">
                <button
                  @click="send_leads_from_home()"
                  :disabled="this.lead_name == '' && this.lead_contact_info == ''"
                  type="button"
                  :class="[
                    this.lead_name != '' && (this.lead_contact_info != '') != ''
                      ? 'send'
                      : 'not_send']">
                  Skicka
                </button>
              </div>
            </div>
           </div>
          </div>
        </div>


    <!-- this.w <= 450 && this.from_page == 'home'"   v-if="contact_requested    " -->
        <div  :class="[ contact_requested  && this.from_page == 'nav'
                          ? 'main_from_page_home_small_nav'
                          : 'main_from_page_home_small_home']"  >

          <div class="open_box_x_icon_main" >
            <b-icon icon="x-lg" @click="open_contact_requested()"></b-icon>
          </div>

           <contactformheadertextMainTitle />

          <div :style="{height:'300px'}" >

            <contactformheadertextjagVillReparera />


            <div class="main_comp">
              <label class="label_size" for="name_input"  >Vilken län bor du i<span :style="{ color: 'red' }"  >*</span ></label    >
              <input    type="text"  v-on:keyup="search_lan_func(lan_name)"   name="name_input"   maxlength="500"  placeholder="Välj län?"  class="input_field"  v-model="lan_name"  />
            </div>
            <div v-if= "this.lan_opened"
            :style="{height:'200px',border:0, position:'absolute',borderWidth:'0',
            boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',overflowY:'auto',borderRadius:'8px',marginTop:'1%',
            opacity:'100%',zIndex:13, backgroundColor:'white',width:'75%', marginLeft:'4%'}"  >
            <div  v-for="(lan,idx) in filtered_lan_list" :style="{marginLeft:'4%'}"
                 :key="idx" @click="choose_lan_func(lan.id,lan.lan)" >
              <div :style="{height:'27px',fontSize:'17px', display:'flex',marginTop:'1%'}" >
                <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
                  {{lan.lan}}
                </div>
              </div>
             </div>
            </div>

            <div class="main_comp">
              <label class="label_size" for="name_input"  >Vilken stad/kommun bor du i<span :style="{ color: 'red' }"  >*</span  ></label    >
              <input
                type="text"
                name="name_input"
                maxlength="500"
                placeholder="Vilken stad bor du i?"
                class="input_field"
                v-model="lead_city"
              />
            </div>

            <contactformTitleTellProblem/>

            <div class="main_comp_valj_produkt">
              <label class="label_size" for="name_input" >Vad behöver lagas? <span :style="{ color: 'red' }">*</span></label   >
              <input
                type="text"
                v-on:keyup="get_company_search(search_item)"
                name="name_input"
                maxlength="500"
                placeholder="Välj produkt/kategori"
                class="input_field"
                v-model="search_item"   />
              <b-icon class="open_box_x_icon_search"
                icon="x-lg"   @click="open_search()"
                v-if="this.search_item_flag == 1"  />
              <div  v-if="this.search_item_flag == 1" :style="{height:'200px',border:0, position:'absolute',borderWidth:'0',
              boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'8px',marginTop:'1%',
              opacity:'100%',zIndex:13, backgroundColor:'white',width:'75%', marginLeft:'4%'}">

              <div :style="{height:'200px',width:'100%',overflowY:'auto'}">
                <div :style="{ height: '200px', width: '100%', overflowY: 'auto' }">
                  <div  v-for="(search, idx) in search_list"
                    :key="idx"  :style="{  marginLeft: '1%', textAlign: 'left',  width: '99%',    height: '27px',  fontWeight: 'bold', marginTop: '1%'  }"  >
                    <div   :style="{   width: '100%',   height: '27px',    display: 'flex',  marginTop: '1%'  }"
                      @click="choose_category(search.id, search.category)"   >
                      <div    :style="{   marginLeft: '1%',       marginTop: '1%',
                          width: '100%',   fontSize: '17px', lineHeight: '13px',   fontWeight: 500,  height: '35px',  color: 'black'}"  >
                        {{ search.category }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="main_comp_finns_kategori">
              <label class="label_size" for="name_input"  >
                Finns inte det du vill laga i listan?<br>
                Skriv in den här
                </label    >
              <input
                type="text"
                name="name_input"
                maxlength="500"
                placeholder="Kategori av föremål"
                class="input_field"
                v-model="category_suggestion"
              />
            </div>

            <div class="main_comp_finns_kategori">
              <label class="label_size" for="name_input" >Vad är du villig att betala (prisintervall)?<span :style="{ color: 'red' }">*</span></label    >
              <input  type="text"   name="name_input"   maxlength="500"    placeholder="Vad har du för prisintervall"
              class="input_field"
                v-model="price_interval"   />
            </div>



            <div class="main_comp_desc">
              <div class="label_size_descr">Beskriv det du vill laga och information om den (ex märke, modell mm)
                <!-- sälja -->
              </div>
              <textarea
                name="name"
                v-model="description"
                rows="8"
                :style="{ height: '140px', resize: 'none', width: '95%' }"
                cols="80"
              ></textarea>
            </div>

            <contactFormUploadImagesHeader/>

            <div class="main_comp_home">
              <multiple-image-upload   v-model="imagesToUpload"   />
            </div>

            <contactFormContactInfo/>

            <div class="main_comp">
              <label class="label_size" for="name_input" >Namn<span :style="{ color: 'red' }">*</span></label    >
              <input  type="text"   name="name_input"   maxlength="500"    placeholder="Fyll i ditt namn"  class="input_field"
                v-model="lead_name"   />
            </div>

            <div class="main_comp">
              <label class="label_size" for="name_input">
                Kontaktinformation  <span :style="{ color: 'red' }">*</span></label>
              <input  type="text"  name="name_input"  maxlength="500"
                placeholder="eg emailaddress" class="input_field"
                v-model="lead_contact_info"    />
            </div>

            <contactFormConsent/>

            <div :style="{width:'92%',height:'110px',marginTop:'2%',marginLeft:'4%',display:'flex'}">
              <input class="checkbox" type="checkbox" name="" value="" v-model="check">
              <div :style="{width:'88%',marginLeft:'6%'}">
                Jag förstår att de kontaktuppgifter jag skriver här delas med reparatörer i det område jag har uppgett.
                <!-- Jag förstår att de kontaktuppgifter jag skriver här delas med Enjord. -->

              </div>
            </div>

            <contactformOurReplyPeriodInfo/>

            <div :style="{ height: '70px', display: 'flex', textAlign: 'center' }">
              <div :style="{ width: '70%' }">
                <button   @click="send_leads_from_home()"
                  :disabled="this.lead_name == '' && !this.check && this.lead_contact_info == ''"
                  type="button"
                  :class="[
                    this.lead_name != '' && this.check && (this.lead_contact_info != '') != ''
                      ? 'send'
                      : 'not_send' ]">
                  Skicka <b-icon icon="cursor-fill" :style="{height:'14px',width:'14px', color:'white' }" />
                </button>
              </div>
            </div>

            <contactFormContactUs  />

          </div>
        </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../../config.js";
import { bus } from "../main";

import emailjs from "@emailjs/browser";
import MultipleImageUpload from "@/components/multiple_image_upload.vue";

import contactFormContactInfo from "@/components_small/contact_form_contact_info.vue";
import contactFormConsent from "@/components_small/contact_form_consent.vue";
import contactFormUploadImagesHeader from "@/components_small/contact_form_upload_images_header.vue";
import contactFormContactUs from "@/components_small/contact_form_contact_us.vue";

import contactformheadertextjagVillReparera from "@/components_small/contact_form_header_text_jagVillReparera.vue";
import contactformheadertextMainTitle from "@/components_small/contact_form_header_text_main_title.vue"

import contactformTitleTellProblem from "@/components_small/contact_form_title_tell_problem.vue"

import contactformOurReplyPeriodInfo from "@/components_small/contact_form_our_reply_period_info.vue"
import contactFormRequestSending from "@/components_small/contact_form_request_sending.vue"
import contactFormRequestSent from "@/components_small/contact_form_request_sent.vue"

export default {
  components: {
     MultipleImageUpload,contactFormContactUs,contactformheadertextjagVillReparera ,contactFormRequestSent,contactFormRequestSending,
    contactFormContactInfo,contactFormConsent,contactFormUploadImagesHeader,contactformheadertextMainTitle,contactformTitleTellProblem,contactformOurReplyPeriodInfo},
  props: ["company_id", "verified", "from_page"],
  data: function () {
    return {
      publicPath: process.env.BASE_URL + "",
      lead_name: "",
      check:false,
      lead_company: "",
      lead_contact_info: "",
      leads_sent: false,
      contact_requested: true,
      w: 0,
      selectedFile: "",
      image: "",
      image_list: [],
      description: "",
      search_item: "",
      lead_city: "",
      produktens_namn: "",
      produktens_measurements: "",
      antal_produkter: "",
      loading: false,
      info_box_opened: false,
      page_nr: 1,
      search_list: [],
      search_item_flag: 0,
      lan_list: [],
      lan_opened: false,
      lan_name: "",
      lan_id: 0,
      image_uploaded: false,
      lan_chosen: false,
      category_id: 0,
      category_suggestion:"",
      leads_sent_general: false,
      imagesToUpload: [],
      price_interval:""
    };
  },
  methods: {
    back_page() {
      this.page_nr = this.page_nr - 1;
      this.image_uploaded = true;
    },
    open_info_box() {
      if (this.info_box_opened) {
        this.info_box_opened = false;
      } else {
        this.info_box_opened = true;
      }
    },
    open_search() {
      this.search_item = "";
      this.search_item_flag = 0;
    },
    get_image(id, image) {
      if (image) {
        var data = "enjordrepair_user" + id + "/" + image;
        return this.$func.get_image(data);
      }
    },
    send_leads_from_home() {
      this.contact_requested = false;
      this.loading = true;
      var caller_info = {
        lead_name: this.lead_name,
        lead_contact_info: this.lead_contact_info,
        lead_company: this.lead_company,
        description: this.description,
        category_suggestion:this.category_suggestion,
        lead_city: this.lead_city,
        lan_id: this.lan_id,
        category_id: this.category_id,
        price_interval:this.price_interval
      };
      var self = this;
      axios({
        method: "post",
        url: API_URL + "/create_lead_from_home_page",
        data: {
          company_id: this.company_id,
          caller_info: caller_info,
          images: this.imagesToUpload,
        },
      }).then(function () {
        self.email_contact("enjord23@gmail.com", "Generell leadförfrågan");
        self.loading = false;
        self.leads_sent_general = true;
      });
    },
    search_lan_func(search_lan) {
      this.lan_opened = true
      if (search_lan == "") {
         this.lan_opened=false
        this.filtered_lan_list = this.lan_list
      } else {
        this.lan_opened = true;
        this.filtered_lan_list = this.lan_list.filter((lan_object) =>
          lan_object.lan.toLowerCase().startsWith(search_lan.toLowerCase())
        );
      }
    },
    choose_category(id, category_name) {
      this.search_item = category_name;
      this.category_id = id;
      this.search_item_flag = 0;
    },
    next_page() {
      this.page_nr = this.page_nr + 1;
    },
    close_leads_sent() {
      this.leads_sent = false;
      this.loading=false
      this.leads_sent_general = false;
      bus.$emit("close_contact_form", false);
    },
    choose_lan_func(lan_id, lan_name) {
      this.lan_id = lan_id;
      this.lan_name = lan_name;
      this.lan_chosen = true;
      this.lan_opened = false;

      axios({
        method: "post",
        url: API_URL + "/click_lan",
        data: { lan_id: lan_id },
      }).then(function () {});
    },
    get_company_search() {
      if (this.search_item == "") {
        this.search_item_flag = 0;
      } else {
        this.search_item_flag = 1;
      }
      var self = this;
      axios({
        method: "get",
        url:
          API_URL +
          "/search_categories_analytics" +
          "?search_item=" +
          this.search_item,
      }).then(function (response) {
        self.search_list = response.data;
      });
    },

    email_contact(to_name_var, company) {
      //      ,lead_id
      var templateParams = {
        to_name: to_name_var,
        company: company,
      };
      emailjs
        .send(
          "service_54orlpk",
          "template_yjqlltb",
          templateParams,
          "2HGpsoHrhTkdtQlEN"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            // axios({
            //     method:'put',
            //     url: API_URL+ '/update_lead_emailed',
            //     data:{lead_id:lead_id}
            //   })
            //   .then(function(){
            //   })
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
    open_lan() {
      if (this.lan_opened) {
        this.lan_opened = false;
      } else {
        this.lan_opened = true;
      }
    },
    open_contact_requested() {
      if (this.contact_requested) {
        this.contact_requested = false;
      } else {
        this.contact_requested = true;
      }
      bus.$emit("close_contact_form", this.contact_requested);
    }
  },

  mounted() {
    console.log(this.contact_requested);
//    this.w = window.screen.width;

    this.category_id =this.$store.state.category_object.category_id
    this.search_item = this.$store.state.category_object.category


    this.lan_list = [
      { id: 1, lan: "Blekinge län" },
      { id: 2, lan: "Dalarnas län" },
      { id: 3, lan: "Gotlands län" },
      { id: 4, lan: "Gävleborgs län" },
      { id: 5, lan: "Hallands län" },
      { id: 6, lan: "Jämtlands län" },
      { id: 7, lan: "Jönköpings län" },
      { id: 8, lan: "Kalmar län" },
      { id: 9, lan: "Kronobergs län" },
      { id: 10, lan: "Norrbottens län" },
      { id: 11, lan: "Skåne län" },
      { id: 12, lan: "Stockholms län" },
      { id: 13, lan: "Södermanlands län" },
      { id: 14, lan: "Uppsala län" },
      { id: 15, lan: "Värmlands län" },
      { id: 16, lan: "Västerbottens län" },
      { id: 17, lan: "Västernorrlands län" },
      { id: 18, lan: "Västmanlands län" },
      { id: 19, lan: "Västra Götalands län" },
      { id: 20, lan: "Örebro län" },
      { id: 21, lan: "Östergötlands län" },
    ];

    //    this.filtered_lan_list=this.lan_list
  },

}
</script>

<style lang="css" scoped>
.show_chosen_image_first {
  height: 70px;
  border-radius: 8px;
  width: 70px;
  display: flex;
  margin-left: 8%;
  object-fit: contain;
  /* position: absolute; */
  background-color: white;
}


@media (max-width: 450px) {
  .leads_sent_button{
      color: #0A6338;
      cursor: pointer;
      margin-top:  1%;
      margin-left:  80%;
  }

  .open_gear_box_outside_loading{
    position: fixed;
    height: 100%;
    opacity: 0.5;
    background: black;
    z-index: 5;
    width: 100%;
    left: 0px;
    top:0px;
  }

  .loading{
    width: 90%;
    background-color:  white;
    border-radius: 67px;
    border: 5px solid #0A6338;
    color: #0A6338;
    z-index:  6;
    position: fixed;
    margin-left:  4%;
    font-size: 25px;
    padding-top:  15%;
    margin-top:  24%;
    height: 42%;
  }

  .open_gear_box_outside_sent{
    position: fixed;
    height: 100%;
    opacity: 0.5;
    background: black;
    z-index: 9;
    width: 100%;
    left: 0px;
    top:0px;
}

.main_from_page_home_large{
  display: none;
}

.main_from_page_home_small_nav{
  width: 100%;
  text-align: left;
  z-index: 6;
  position: fixed;
  margin-left: 0%;
  margin-top: -1%;
  height:100%;
  padding-bottom: 0px;
  overflow-y: scroll;
  background: #FFFFFF;
     /* border-radius: 30px; */
}


.main_from_page_home_small_home{
  width: 100%;
  text-align: left;
  z-index: 6;
  position: fixed;
  margin-left: 0%;
  margin-top: -28%;
  height:100%;
  padding-bottom: 0px;
  overflow-y: scroll;
  background: #FFFFFF;
     /* border-radius: 30px; */
}


.open_box_x_icon_main {
  margin-Left: 89%;
  margin-top: 2%;
  font-scale: 12;
  height: 20px;
  color: black;
  position: absolute;
  font-Size: 20px;
}

.leads_sent{
    width: 90%;
    background-color: white;
    border-radius: 67px;
    border: 5px solid #0A6338;
    color: #0A6338;
    z-index: 10;
    position: fixed;
    margin-Left: 4%;
    font-Size: 20px;
    padding-Top: 5%;
    margin-Top: 2%;
    height: 62%
}


.lan_button_main{
    border-style:  none;
    margin-left:  1%;
    font-size:  12px;
    border-radius: 3px 0px 0px 3px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
    background-color: #0A6338;
    width: 180px;
    color: white;
}


.lan_opened_box{
    height:200px;
    border:0;
    position:absolute;
    border-width:0;
    box-shadow: 0px 4px 20px rgba(199, 199, 199, 0.6);
    border-radius:0px 3px 3px 0px ;
    margin-top:0%;
    overflow-y:auto;
    border-radius:3px;
    border-color:black;
    opacity:100%;
    z-Index:13;
    width:55%;
    margin-top:0.5%;
    background-color:white;
    margin-left:0%;
}



  .reply_text{
    width: 230px;
    margin-left: 25%;
  }


  .back_page {
    color: white;
    padding-top: 1%;
    margin-top: 0%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 100px;
    height: 35px;
    border-style: None;
    border-radius: 18px;
    background-color: #0A6338;
    /* position: absolute; */
  }

  .checkbox{
      accent-color:#0A6338;
      position: absolute;
      margin-top: 0%;
  }

  .main_comp_desc {
    margin-left: 0%;
    margin-top: 0%;
    height: 200px;
  }

  .main_comp {
    height: 65px;
    margin-left: 5%;
    margin-top: 0%;
  }


  .main_comp_finns_kategori{
    height: 105px;
    margin-left: 0%;
    margin-top: 2%;

  }



  .main_comp_valj_produkt {
    margin-left: 7%;
    margin-top: 0%;
    height: 70px;
  }

  .main_comp_home {
    display: flex;
    height: 105px;
    width: 90%;
    margin-left: 2%;
    margin-top: 0%;
  }

  .label_size_namn {
    height: 20px;
    width: auto;
    color: black;
  }

  .main_comp_namn {
    height: 65px;
    width: auto;
    margin-left: 7%;
    margin-top: 0%;
  }

  .main_comp_antal {
    height: 31px;
    display: flex;
    margin-left: 7%;
    margin-top: 0%;
  }

  .main_descr {
    height: auto;
    margin-left: 4%;
    margin-top: 0%;
  }

  .input_field_antal {
    border: 2px solid #0A6338;
    color: black;
    margin-top: 0%;
    width: 40%;
    margin-left: 3%;
    border-radius: 8px;
    height: 29px;
  }


  .input_field {
    border: 2px solid #0A6338;
    color: black;
    margin-top: 0%;
    width: 88%;
    margin-left: 2%;
    border-radius: 8px;
    height: 29px;
  }

  .title {
    width: 85%;
    height: 50px;
    margin-top: 7%;
    font-size: 18px;
    margin-left: 4%;
    line-height: 22px;
    color: #0A6338;
    line-height: normal;
  }

  .label_size_descr {
    color: black;
    /* width: 120px; */
  }
  .send {
    color: white;
    padding-top: 1%;
    margin-left: 45%;
    margin-top: 2%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 100px;
    height: 35px;
    border-style: None;
    border-radius: 18px;
    background-color: #0A6338;
    /* position: absolute; */
  }

  .not_send {
    color: white;
    padding-top: 1%;
    margin-left: 45%;
    margin-top: 2%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 100px;
    height: 35px;
    border-style: None;
    border-radius: 18px;
    background-color: grey;
  }

  .label_size_bild {
    width: 299px;
    display: flex;
    margin-left: 2%;
    color: black;
  }

  .label_size_antal {
    width: auto;
    height: 20px;
    padding-right: 1%;
  }

  .open_box_x_icon_search{
    width: 15px;
    color: black;
    margin-Left: -6%;
    position: absolute;
    margin-Top: 1%;
    cursor: pointer
  }


  .contact_requested{
    width: 80%;
    text-Align: left;
    z-Index: 6;
    position: fixed;
    margin-left: 14%;
    margin-top: 1%;
    height: 95%;
    background: #FFFFFF;
    border-Radius: 30px;
  }

  .label_size {
    margin-left: 2%;
    width: auto;
    color: black;
  }
  .main_from_page_home_large_nav{
      display: none
  }
  .main_from_page_home_large_home{
    display: none
  }

}

@media (min-width: 450px) {
  .loading{
    width: 90%;
    background-color:  white;
    border-radius: 67px;
    border: 5px solid #0A6338;
    color: #0A6338;
    z-index:  6;
    position: fixed;
    margin-left:  5%;
    font-size: 25px;
    padding-top:  15%;
    margin-top:  -8%;
    height: 82%;
  }

  .main_from_page_home_small_nav{
    display: none;
  }
  .main_from_page_home_small_home{
    display: none;
  }

  .leads_sent_button{
      color: #0A6338;
      cursor: pointer;
      margin-top:  1%;
      margin-left:  80%;
      font-size: 45px;
      min-width: 40px;
      min-height: 40px;
  }

  .main_from_page_home_large_home{
      width: 80%;
      text-align: left;
      z-Index: 6;
      position: fixed;
      margin-left: 14%;
      margin-top: -4%;
      height: 99%;
      background: #FFFFFF;
      border-radius: 30px;
  }

.main_from_page_home_large_nav{
  width: 80%;
  text-align: left;
  z-Index: 6;
  position: fixed;
  margin-left: 14%;
  margin-top: 0%;
  height: 99%;
  background: #FFFFFF;
  border-radius: 30px;

}


  .open_box_x_icon_main{
    margin-left: 89%;
    margin-top: 2%;
    font-scale: 12;
    height: 30px;
    position: absolute;
    font-Size: 30px;
    color: black;
  }


  .leads_sent{
      width: 90%;
      background-color: white;
      border-radius: 67px;
      border: 5px solid #0A6338;
      color: #0A6338;
      z-index: 10;
      position: fixed;
      margin-Left: 5%;
      font-Size: 20px;
      padding-Top: 2%;
      margin-Top: -8%;
      height: 82%
  }

  .main_comp {
    margin-left: 7%;
    margin-top: 1%;
    height: auto;
    width: auto;
  }

  .main_comp_home {
    height: 45px;
    width: 90%;
    margin-left: 2%;
    margin-top: 0%;
  }

  .main_comp_finns_kategori{
    height: 75px;
    margin-left: 7%;
    margin-top: 0%;
  }


  .open_box_x_icon_search{
    width: 15px;
    color: black;
    margin-Left: -2%;
    position: absolute;
    margin-Top: 0.5%;
    cursor: pointer
  }

  .contact_requested{
    width: 100%;
    z-index: 2;
    background-color: black;
    opacity: 0.5;
    position:fixed;
    margin-left:  0%;
    margin-top:  0%;
    height: 100%;
  }

  .main_comp_finns_kategori{
    height: 85px;
    margin-left: 7%;
    margin-top: 0%;
  }

  .lan_opened_box{
      height:200px;
      border:0;
      position:absolute;
      border-Width:0;
      box-shadow: 0px 4px 20px rgba(199, 199, 199, 0.6);
      border-radius:0px 3px 3px 0px ;
      margin-top:0%;
      overflow-Y:auto;
      border-Radius:3px;
      border-Color:black;
      opacity:100%;
      z-Index:13;
      width:35%;
      margin-Top:0.5%;
      background-Color:white;
      margin-Left:15%;
  }

 .lan_button_chosen_lan{
      border-style: none;
      margin-left:  1%;
      font-color: #8A8A8A;
      border-radius: 3px 0px 0px 3px;
      box-shadow:  0px 4px 9px 0px rgba(0, 0, 0, 0.25);
      background-color: #0A6338;
      color: white;
      width: 180px;
  }

  .label_size_bild {
    width: 299px;
    display: flex;
    margin-left: 2%;
    color: black;
  }

  .label_size_bild_kontakta {
    width: 299px;
    display: flex;
    margin-left: 0%;
    color: black;
  }

  .back_page {
    color: white;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 80px;
    height: 35px;
    margin-left: 2%;
    border-style: None;
    border-radius: 18px;
    background-color: #0A6338;
  }

  .next_page {
    color: white;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 80px;
    height: 35px;
    margin-left: 82%;
    border-style: None;
    border-radius: 18px;
    background-color: #0A6338;
    /* position: absolute; */
  }

  .main_comp_valj_produkt {
    margin-left: 7%;
    margin-top: 1%;
    height: 40px;
  }

  .input_field_antal {
    border: 2px solid #59419b6f;
    color: black;
    margin-top: 0%;
    width: 40%;
    margin-left: 3%;
    border-radius: 8px;
    height: 29px;
  }

  .input_field {
    border: 2px solid #59419b6f;
    color: black;
    margin-top: 0%;
    width: 48%;
    margin-left: 1%;
    border-radius: 8px;

    height: 29px;
  }

  .title {
    width: 75%;
    height: 40px;
    margin-top: 3%;
    font-size: 25px;
    margin-left: 7%;
    color: #0A6338;
    line-height: normal;
  }

  .send {
    color: white;
    padding-top: 0.5%;
    margin-right: 0%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 90px;
    height: 35px;
    border-style: None;
    border-radius: 18px;
    background-color: #0A6338;
  }

  .not_send {
    color: white;
    padding-top: 0.5%;
    margin-right: 0%;
    padding-bottom: 0.5%;
    font-size: 16px;
    width: 90px;
    height: 35px;
    border-style: None;
    border-radius: 18px;
    background-color: grey;
  }

  .label_size {
    width: 300px;
    color: black;
  }


}

.label_size_descr {
  color: black;
  /* width: 590px; */
}

.search_class {
  width: 100%;
  height: 30px;
  margin-left: 7%;
  /* background: lightgrey; */
  /* rgba(196, 196, 196, 0.32); */
  border-radius: 3px;
  display: flex;
  /* border-style: solid; */
}

</style>
