<template lang="html">
  <div class="main" >

    <div class="open_gear_box_outside" @click="open_contact_form()" v-if="this.checked_contact_form">
    </div>


    <contactformGeneral  v-if="checked_contact_form" :company_id="company_id" :verified="verified"  from_page="nav"/>

    <div  v-if="this.menu_clicked"
      @click="menu_clicked_func()"
      class="menu_clicked"
    ></div>

    <div class="main_menu" v-if="menu_clicked">
      <div  :style="{width:'94%', height:'30px',display:'flex',marginLeft:'0%', marginTop:'3%',  justifyContent: 'space-between'}">
        <div  :style="{height:'30px',paddingLeft:'2%', fontSize:'22px', cursor:'pointer',marginTop:'0%',color:'#0A6338', width:'20px'}">
             Menu
        </div>
        <b-icon icon="x-lg"  @click="menu_clicked_func()" :style="{width:'20px', height:'20px', color:'#0A6338'}" />
      </div>
      <div :style="{width: '100%',  borderWidth:'1px', borderStyle:'solid',borderColor:'black'}">
      </div>
      <div class="menu_main" >
        <div @click="to_explore()" :style="{width: '100%', paddingLeft:'2%', height: '30px', color:'#0A6338' }">
          Hem
        </div>
        <div @click="to_about()" :style="{width: '100%', paddingLeft:'2%', height: '30px', color:'#0A6338' }">
          Om oss
        </div>
        <div @click="to_signup_login()" :style="{width: '100%', paddingLeft:'2%', height: '30px',color:'#0A6338'}">
          Logga in
        </div>
        <div @click="to_register()" :style="{width: '100%', paddingLeft:'2%', height: '30px',color:'#0A6338'}">
            Registrera
        </div>
      </div>
    </div>


     <div class="enjord_text_mobile" >
       <div :style="{display:'flex',   justifyContent: 'spaceBetween'}">
         <div @click="to_explore()"  :style="{ height: '40px', textAlign: 'left', fontWeight:'600',fontSize: '32px',marginLeft:'2%', cursor: 'pointer', color: '#0A6338', width: '315px' }">
           Enjord
         </div>
         <div  @click="menu_clicked_func()"  :style="{  display: 'flex',height:'50px', textAlign: 'right', cursor: 'pointer', marginRight:0,marginTop: '0%',marginLeft:'0%' , width: '40px' }">
           <b-icon icon="list" class="menu_icon" />
         </div>
         <!-- <div  @click="open_contact_form()"  :style="{  display: 'flex',height:'50px', textAlign: 'right', cursor: 'pointer', marginRight:0,marginTop: '0%',marginLeft:'20%' , width: '40px' }">
            <b-icon  icon="arrow-up-square-fill" class="menu_icon" ></b-icon>
           <b-icon icon="search" class="menu_icon" />
         </div> -->
       </div>
       <div class="enjord_text_mobile_underline">
       </div>
     </div>


    <div  class="main_menu_computer">

      <router-link to="/" >
        <div   :style="{ height: '40px', textAlign: 'left',marginTop:'2%' ,fontWeight:'600',fontSize: '32px',marginLeft:'2%', cursor: 'pointer', color: '#0A6338', width: '315px' }">
          Enjord
        </div>
<!--
        <img   alt="enjord-logo"  class="image_logo_large" :src= "`${publicPath}`+ 'enjord_logo.png'" /> -->
      </router-link>
      <!-- <div @click="open_guide_full()"   :style="{height:'35px',  cursor:'pointer',marginTop:'0.3%', fontSize:'20px',marginLeft:'2%', width:'35px',borderRadius:'50%'}">
        Guide
     </div> -->

      <div  v-if="this.guide_open"
         :style="{height:'150px',border:0, position:'absolute',borderWidth:'0',textAlign:'left',
                 overflowY:'auto', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
                 borderRadius:'6px 0px 0px 6px',marginTop:'2.6%', opacity:'100%',zIndex:13,
                 width:'311px',color:'black' ,backgroundColor:'white',marginLeft:'2%'}">
          <div v-for="(guide,idx) in guide_list" :key="idx"  @click="to_guide(guide.id)">
              {{guide.guide}}
          </div>
      </div>

       <div @click="open_categories()"  id="kat"
            class="main_p">
          Kategorier
       </div>
       <div  v-if="this.category_open" for="kat" :style="{height:'150px',border:0, position:'absolute',borderWidth:'0',
           overflowY:'auto', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
           borderRadius:'6px 0px 0px 6px',marginTop:'4%', opacity:'100%',zIndex:13,
           width:'21%',lineHeight:'14px', backgroundColor:'white',marginLeft:'22%'}"    >
           <div v-for="(category,idx) in huvud_category_list"
                :key="idx"    @click="open_kategori(category.id,category.main_category_svenska)"
              :class="[ category.category_open  ? 'button_checked' : 'button_unchecked' ]">
             <div :style="{height:'16px', display:'flex',marginTop:'1%',paddingTop:'2.5%'}" >
               <div :style="{marginLeft:'1%'}">
                 {{category.main_category_svenska}}
               </div>
             </div>
             <!-- <div v-if="category.category_open == true" :style="{zIndex:13,position:'relative', opacity:1,height:'120px'}" >
               <categorylist  :in_category="category.huvud_kategori" :open_status_var="category.category_open" />
             </div> -->
           </div>
       </div>
       <div  v-if="this.subcategory_open"
          :style="{height:'150px',border:0, position:'absolute',
                borderWidth:'0',overflowY:'scroll', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
                borderRadius:'0px 6px 6px 0px',marginTop:'4%',
                borderColor:'black', opacity:'100%',
                zIndex:13, width:'18%',
                 backgroundColor:'white',marginLeft:'43%'}">
         <div  v-for="(category,idx) in category_list"
               :key="idx"
               @click="to_category(category.id,category.category)" >
           <div :style="{height:'20px',textAlign:'left',paddingLeft:'1.5%', lineHeight:'16px',fontSize:'15px',display:'flex',marginTop:'1%'}" >
             <div :style="{marginLeft:'0%',marginTop:'0.5%', color:'black'}">
               {{category.category}}
             </div>
           </div>
         </div>
       </div>



       <!-- <div :style="{marginTop:'2%'}" > -->
          <!-- <a href='https://www.enjord.com/about' :style="{ color:'white'}"  >
            Om Enjord
            :style="{height:'35px',  cursor:'pointer',marginTop:'0.3%', fontSize:'20px',marginLeft:'10%', width:'105px',borderRadius:'50%'}">

           </a> -->
           <router-link to="/about" class="main_p">
              Om Enjord
           </router-link>
       <!-- </div> -->
       <!-- :style="{height:'35px', color:'bla',fontSize:'20px', cursor:'pointer',marginLeft:'15%',marginTop:'0.3%', width:'50px',borderRadius:'50%'}"> -->

       <!-- <div     > v-if=" this.user_id ===0"-->
           <router-link to="/login_signup" class="main_p"  >
             Login/Registrering
           </router-link>
         <!-- <a  href='https://www.enjord.com/login_signup' :style="{ color:'white'}"  >
           Login/Registrering
          </a> -->
       <!-- </div> -->

       <!-- :style="{height:'35px',  cursor:'pointer',marginTop:'2%',marginLeft:'15%',backgroundColor:'#0A6338', width:'35px',borderRadius:'50%'}" -->

       <!-- <img v-if=" this.user_id != 0"
         @click="to_profile()"
         :src= "`${publicPath}`+ 'profile.jpg'"
         class="main_p_profile"
        /> -->

<!-- :style="{height:'35px',  cursor:'pointer',marginTop:'2%', fontSize:'20px',marginLeft:'2%', width:'185px'}" -->
       <!-- <div  class="jag_vill_reparera"  >
          <div @click="open_contact_form()"      >
            Jag vill reparera
          </div>
       </div> -->

     </div>

  </div>
</template>

<script>
//import menu from '../components/menu.vue';
import contactformGeneral from '../components/contact_form_general_request.vue';
import router from '../router/index'
//    import categorylist from './category_list.vue';
import axios from 'axios';
import {API_URL} from '../../config.js'
import {bus} from '../main'
export default {
  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      category_open:false,
      category_list:[],
      subcategory_open:false,
      huvud_category_list:[],
      w:0,
      menu_clicked:false,
      guide_open:false,
      guide_list:[],
      checked_contact_form:false,
      user_id:0

    }
  },
  beforeUpdate (){
    if(this.category_open && this.category_list.length==0 &&this.w >450 ){
            this.get_main_categories()
    }
  },
  components:{

    contactformGeneral,
    //menu
    // Search
//    categorylist
  },
  mounted(){
    this.w = window.screen.width

    bus.$on('close_contact_form', (val) => {
      this.checked_contact_form = val
    })

    if(this.$store.state.current_user ==undefined || this.$store.state.current_user.user_id==0 || window.localStorage.current_user ==undefined){
        this.user_id=0
    }
    else{
      this.user_id =this.$store.state.current_user.user_id
    }

    this.guide_list =[
        {id:0, guide:'Hur funkar Enjord' },
        {id:1, guide:'Vad kan lagas' },
        {id:2, guide:'Miljövinsterna av att laga' },
        {id:3, guide:'Ekonomiska vinster av att laga' },
      ]

  },

  methods:{
    to_register(){
      this.menu_clicked=false

      router.push({name:'register'})

    },

    to_profile(){
      router.push({name:'profile'})
    },
    to_about(){
        this.menu_clicked=false
      router.push({name:'About'})
    },
    to_search_page(){
        this.menu_clicked=false
      router.push({name:'search_page'})
    },

    menu_clicked_func(){
      if(this.menu_clicked){
        this.menu_clicked=false
      }else{
        this.menu_clicked=true
      }
    },
    to_guide(id){
      router.push({name:'guide', query:{id:id}})
    },

    open_guide_full(){
      if(this.guide_open){
        this.guide_open=false
      }else {
        this.guide_open = true
      }
    },
    open_contact_form(){
      if(this.checked_contact_form){
        this.checked_contact_form=false
      }else {
        this.$store.commit('set_category_explore_page_general_req_contact_form', {"category":'', "category_id":0})

        this.checked_contact_form=true
        // axios({
        //   method:'post',
        //   url: API_URL+ '/gar_det_att_laga',
        //   data:{}
        // })
        // .then(function(){
        //  })
      }

        this.verified=false
        this.company_id=0
    },
    open_kategori(id,huvud_kategori){
      for (let ii = 0; ii < this.huvud_category_list.length;ii++){
                this.huvud_category_list[ii].category_open =  false
            }
          this.huvud_category_list[id].category_open = true
          this.choose_main_category(huvud_kategori)
          this.subcategory_open = true

    },
    to_signup_login(){
      this.menu_clicked=false

      router.push({name:'login_signup'})
    },
    to_explore(){
      this.menu_clicked=false
      router.push({name:'explore_page'})
    },
    to_category(category_id,category_name){
        this.$forceUpdate()
        axios({
          method:'post',
          url: API_URL+ '/click_category',
          data:{'category_id':category_id, lan_id:0}
        })
        .then(function(){
         })
        router.push({ path:"result_page", query:{category_id:category_id, from_page:'category', category_name:category_name, lan_id:0} }).catch(()=>{})
        // router.push({name:'result'})
    },
    choose_main_category(category){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/choose_main_category'+'?category='+category,
        })
        .then(function(response){

            self.category_list=response.data
        })

    },
    open_categories(){
      for (let ii = 0; ii < this.huvud_category_list.length;ii++){
                this.huvud_category_list[ii].category_open =  false
            }
          if(this.category_open){
            this.subcategory_open = false
            this.category_open = false
          }
          else{
              this.category_open = true
          }
    },
    get_main_categories(){
        var self = this
        axios({
            method:'get',
            url: API_URL+ '/get_main_categories',
          })
          .then(function(response){
              self.huvud_category_list = response.data
          })
    }


  }
}
</script>

<style lang="css" scoped>

a {
      text-decoration: none;
}


.category_main_div{
  height:150px;
  background-color: white;
  border:0;
  position:absolute;
  border-width:0;
  overflow-y: auto;
  box-shadow:  0px 4px 20px rgba(199, 199, 199, 0.6);
  border-radius: 6px 0px 0px 6px;
  margin-top: 4%;
  opacity:100%;
  z-index: 13;
  width:21%;
  line-height: 14px;
  background-color: white;
  margin-left: 22%;
}


@media (min-width:450px ){
  .open_gear_box_outside{
    position: fixed;
    height: 100%;
    opacity: 0.5;
    background: black;
    z-index: 1;
    width: 100%;
    left: 0px;
    top:0px;
}

  .enjord_text_mobile{
    display: none
  }

  .main_p{
    color: #012333;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin-top: 16px;
    margin-left: 8%;
  }

  .main_p_profile{
    color: #012333;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;
    margin-top: 11px;
    margin-left: 8%;

  }

  .enjord_text_mobile_underline{
      display: none
  }


  .image_logo_large{
    height:57px;
    cursor:pointer;
    margin-top:2%;
    margin-left: 1%;
    width:132px;

  }

  .main_menu{
      display: none;
  }

  .jag_vill_reparera{
      width: 190px;
      height: 47px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color:  #0A6338;
      white:color;
      margin-top: 7px;
      margin-left: 9%;
      border-radius: 6px;
      color: white;
  }

  .main_menu_computer{
    width: 90%;
    /* min-width: 1000px; */
    display:flex;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.10);

    margin-left: 5%;
    margin-top: 2%

  }

  .main{
    height: 100px;
    /* background-color:  #0A6338; */
    display: flex;
    /* width: 100%; */
    color: black;

     /* # ;  */
     /* background-color: white; */
    /* color:#0A6338; */
    /* font-weight: bold; */
    /* #C7C7C7; */
    /* justify-content: space-between; */
    /* border-radius: 13px; */
  }

  .button{
    width: 179px;
    height: 35px;
     cursor:pointer;
     margin-left: 10%;
     margin-top: 0.65%;
     color: #FFFFFF;
     position: absolute;
     background-color: lightgrey;

  }

  .text1{
    cursor: pointer;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 43px;
    text-align: center;
    color: white;
    margin-left: 5%;
    letter-spacing: -0.01em;
  }




  .button_unchecked{
    margin-left:1%;
    text-align:left;
    height:25px;
     font-size:16px;
       margin-Top:1%;
       color:black
  }

  .button_checked{
    margin-left:1%;
    text-align:left;
    height:29px;
     font-size:16px;
       color: white;
       background-color:#0A6338
  }


}


@media (max-width:450px ){
  /* .open_gear_box_outside{
    position: fixed;
    height: 100%;
    opacity: 0.5;
    background: black;
    z-index: 1;
    width: 100%;
    left: 0px;
    top:0px;
} */

  .image_logo_large{
    display: none
  }

  .main_menu_computer{
        display: none
  }

  .enjord_text_mobile_underline{
      border-width: 1px;
      height:2px;
      border-style:solid;
      border-color: black;
      width:100%;
  }
.menu_icon{
  margin-top: 10%;
  min-height: 30px;
  min-width: 30px;
  margin-left: 2%;
  font-size:  50px;
}



  .main{
    display: flex;
    width: 100%;
    height: 100px;
    color: white;
    background-color:  #0A6338;
     /* # ;  */
     /* background-color: white; */
    /* color:#0A6338; */
    /* font-weight: bold; */
    /* #C7C7C7; */
    /* justify-content: space-between; */
    /* border-radius: 13px; */
  }



.enjord_text_mobile{
  width: 100%;
  height: 50px;
  margin-left: 0%;
  margin-top:  0%;
}

  .menu_clicked{
      width: 100%;
      z-index:  2;
      background-color:  black;
      opacity: 0.5;
      position: fixed;
      margin-Left: 0%;
      margin-top: 0%;
      height: 100%;
  }


  .main_menu{
    z-index: 4;
    width:100%;
    height:200px;
    background-color:  white;
    position: absolute;
  }

  .menu_main{
    text-align: left;
    width:100%;
    margin-top: 2%;
    height: 120px;
    color: #0A6338;
  }

.main{
  display: flex;
  width: 100%;
  height: 20px;
  background-color: white;
  color:#0A6338;
  /* background-color:   #0A6338;
  color:white */
  /* #C7C7C7; */
  /* justify-content: space-between; */
  /* border-radius: 13px; */
}

.button{
  width: 179px;
  height: 35px;
  cursor:pointer;
  margin-left: 10%;
  margin-top: 0.65%;
  color: #FFFFFF;
  position: absolute;
  background-color: lightgrey;

}

.text1{
  cursor: pointer;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 43px;
  text-align: center;
  color: white;
  margin-left: 5%;
  letter-spacing: -0.01em;
}



}
</style>
