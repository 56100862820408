<template lang="html">
  <div class="">
    <div :style="{ width: '98%', marginLeft: '1%' }">
      Skickar din kontaktförfrågan ... <br />
      <!-- Skickar din säljförfrågan ... <br /> -->
      Vänligen vänta...det kan ta några sekunder
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
