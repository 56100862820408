<template lang="html">
  <div class="">
    <div :style="{width:'96%',marginLeft:'4%',textAlign:'center',paddingBottom:'25px',height:'50px'}">
      Har du frågor?<br>
      Mejla oss på info@enjord.com
    </div>
    <div :style="{height:'20px'}">
    </div>
    
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
