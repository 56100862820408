import { func } from "@/GetImageFunc";

export default {
  getS3ImageUrl(companyId, imageName) {
    if (!imageName) {
      return null;
    }
    return func.get_image(`enjordrepair_user${companyId}/${imageName}`);
  },
  /**
   * Normalizes the image name to jpeg, if the image is a jpeg / jpg
   * @param imageFile - the image file
   * @returns {string}
   */
  normalizeJpgImageName(imageFile) {
    const fileSubtype = imageFile.type.split("/")[1];
    let newImageName;
    // normalizing the file name to jpeg
    if (fileSubtype === "jpeg" && imageFile.name.includes("jpg")) {
      const filenameWithoutExtension = imageFile.name.split("jpg")[0];
      newImageName = imageFile.lastModified + filenameWithoutExtension + "jpeg";
    } else {
      newImageName =
        imageFile.lastModified +
        imageFile.name.split(fileSubtype.toString())[0] +
        fileSubtype.toString();
    }
    return newImageName;
  },
};
