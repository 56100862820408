<template lang="html">
  <div class="">
    <div class="main">
     <div :style="{fontSize:'29px',lineHeight:'32px', textAlign:'center',width:'90%' ,color:'#0A6338',fontWeight:'500',height:'auto' ,marginTop:'0%', paddingTop:'2%'}">
       Vad behöver repareras
       <!-- Vad vill du sälja? -->
     </div>
     <div :style="{display:'flex'}">
       <h1 class="h1_tagg">
           Bilder och information hjälper oss hitta rätt reparatör för dig
           <!-- Fyll i information om det du vill sälja så kommer vi utvärdera och återkomma -->
       </h1>
     </div>
     </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>

@media (min-width: 450px) {
  .main{
    width:100%;
    height:70px;
    background-color:white;
    margin-top:1%;
    color:#0A6338;
    border-radius: 30px;
  }

  .h1_tagg{
    line-height: 18px;
    width:85%;
    margin-left: 6.5%;
    text-align: center;
    font-size:16px;
    margin-top:0%;
    letter-spacing: 0px
  }

}


@media (max-width: 450px) {
  .main{
    width:100%;
    height:150px;
    background-color:white;
    margin-Top:10%;
    color:#0A6338
  }

  .h1_tagg{
    line-height: 18px;
    width:85%;
    margin-left: 6.5%;
    text-align: center;
    font-size:16px;
    margin-top:3%;
    letter-spacing: 0px
  }
}

</style>
