<template lang="html">
  <div class="">
    <div :style="{height:'24px',marginLeft:'5%', display:'flex'}">
      <b-icon icon="geo-alt-fill" :style="{height:'24px',width:'24px', color:'#0A6338' }" />
      <div :style="{height:'100%',fontSize: '16px',fontStyle: 'normal',lineHeight: '27px'}">
        Var i Sverige vill du reparera?
        <!-- Var i Sverige finns du? -->
      </div>
    </div>
    <div :style="{borderWidth:'1px', borderStyle:'solid', borderColor:'black', width:'90%', marginLeft:'5%'}">
    </div>

  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
