<template>
  <div class="multiple-image-upload">
    <div class="multiple-image-upload__gallery">
      <!-- <label for="image-upload" v-if="this.w >450 " >
        <img
          :src="`${baseUrl}` + 'upload.png'"
          class="addphoto_camera_gallery"
          alt=""   />
      </label> -->
      <div :style="{display:'flex', height:'auto', paddingBottom:'30px'}">
        <label for="image-upload"
       type="button" name="button"
        :style="{height:'38px',paddingBottom:'2%',textAlign:'center',borderStyle:'none' ,width:'120px',marginLeft:'6%',color:'white', backgroundColor:'#0A6338'}">
          Välj filer
       </label>

      <input
        type="file"
        multiple="multiple"
        id="image-upload"
        @change="uploadMultiplePictures"
      />
      <div  v-if="imagesToUpload.length > 0"
        class="multiple-image-upload__images-to-upload"   >
        <div  v-for="imageToUpload in imagesToUpload"
          class="multiple-image-upload__image-to-upload"
          :key="imageToUpload.id" >
          <div class="multiple-image-upload__new-image-x-icon-container">
            <b-icon   icon="x-lg"
              @click="removeImageFromUploadStage(imageToUpload.id)"
              class="multiple-image-upload__new-image-x-icon"   />
          </div>
          <img   id="output"  :src="getS3ImageUrl(imageToUpload.image)"
            class="show_chosen_image_first"   />
          <!-- <img v-if="!obj.image_uploaded" :id="obj.output"  class="show_chosen_image_first"   alt=""> -->
        </div>
       </div>

      </div>
      <div :style="{borderStyle:'solid', borderColor:'grey',borderWidth:'0.1px',width:'90%'}">

      </div>
      <div :style="{display:'flex', marginTop:'1%',overflowX:'auto', height:'auto', paddingBottom:'5%'}">
        <div  v-for="galleryImage in galleryList"
          class="multiple-image-upload__gallery-image"
          :key="galleryImage.id" >
          <div :style="{display:'flex'}">
            <img  :src="getS3ImageUrl(galleryImage.image)"
              class="show_chosen_image_first" />

            <b-icon
              class="multiple-image-upload__gallery-image-x-icon"
              icon="x-lg"
              @click="deleteImageFromGalleryOnServer(galleryImage.id, galleryImage.image)" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import imageService from "@/services/image.service";
import axios from "axios";
import { API_URL } from "../../config";

export default {
  name: "MultipleImageUpload",
  props: {
    value: {
      type: Array,
      required: true,
    },
    galleryList: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      companyId: 0,
      imagesToUpload: [],
      isInfoBoxOpen: false,
      baseUrl: process.env.BASE_URL,
    };
  },
  watch: {
    imagesToUpload: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  mounted(){
    this.w=window.screen.width
  },
  methods: {
    getS3ImageUrl(imageName) {
      return imageService.getS3ImageUrl(this.companyId, imageName);
    },
    deleteImageFromGalleryOnServer(image_id, image_name) {
      axios({
        method: "delete",
        url: API_URL + "/delete_gallery_image",
        data: {
          image_id: image_id,
          image: image_name,
          company_id: this.companyId,
        },
      }).then(() => {
        this.$emit("update:gallery-images", image_id);
      });
    },
    removeImageFromUploadStage(imageId) {
      this.imagesToUpload = this.imagesToUpload.filter(
        (currentElement) => currentElement.id !== imageId
      );
    },
    toggleInfoBox() {
      this.isInfoBoxOpen = !this.isInfoBoxOpen;
    },
    async uploadMultiplePictures(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const currentFile = event.target.files[i];
        const imageName = imageService.normalizeJpgImageName(currentFile);

        await this.postToS3WithSignedUrlMultiple(currentFile, imageName);

        this.imagesToUpload.push({
          id: this.imagesToUpload.length + i,
          file: currentFile,
          output:
            "output" + this.imagesToUpload.length.toString() + i.toString(),
          image: imageName,
        });
      }
    },
    async postToS3WithSignedUrlMultiple(file, image) {
      const type = file.type;
      const params = {
        Bucket: "garemotest1",
        Key: "enjordrepair_user" + this.companyId + "/" + image,
        ContentType: type,
      };

      const s3ImageUrl = await this.get_AWS().getSignedUrlPromise(
        "putObject",
        params
      );
      try {
        await axios({
          method: "put",
          url: s3ImageUrl,
          data: file,
          headers: {
            "Content-Type": type,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="css">
@media (max-width:450px ){
  .multiple-image-upload {
    width: 99%;
    padding: 3%;
    margin-left: 5%;
    height: 40px;
    margin-top: 0%;
    border-radius: 18px;
    background-color: white;
    /* box-shadow: 0 3px 30px #0000001a; */
  }


  .multiple-image-upload__header {
    margin-left: 4%;
    display: flex;
    font-size: 20px;
    padding-top: 0;
  }

  .multiple-image-upload__headline {
    width: 270px;
  }

  .multiple-image-upload__info-box-trigger {
    height: 20px;
    width: 20px;
    margin-top: 0.5%;
  }

  .multiple-image-upload__info-box-content {
    position: absolute;
    height: 90px;
    width: 380px;
    margin-left: 22%;
    margin-top: 0;
    padding: 0.5%;
    z-index: 3;
    /* box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25); */
    color: black;
    background-color: white;
    font-size: 12px;
  }

  .multiple-image-upload__gallery {
    /* display: flex; */
    align-items: center;
    gap: 2rem;
  }

  .multiple-image-upload__images-to-upload {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.25rem;
    text-align: left;
    margin: 0 auto;
  }

  .multiple-image-upload__image-to-upload {
    height: 40px;
    width: 40px;
    position: relative;
  }

  .multiple-image-upload__new-image-x-icon-container {
    position: absolute;
    background: white;
    padding: 0 3px;
    border-radius: 25px;
    right: 0;
    border: 1px solid grey;
    font-size: 12px;
    transform: translate(50%, -50%);
    cursor: pointer;
  }

  .multiple-image-upload__new-image-x-icon {
    color: black;
  }

  .multiple-image-upload__gallery-image {
    margin-left: 1%;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
  }

  .multiple-image-upload__gallery-image-x-icon {
    color: black;
    margin-left: -29%;
    margin-top: 14%;
    height: 15px;
    width: 15px;

    z-index: 3;
    /* position: absolute; */
    position: relative;
  }

  .addphoto_camera_gallery {
    height: 30px;
    width: 45px;
    margin-left: 0%;
    margin-top: 0%;
    cursor: pointer;
    /* background-color: #33D075; */
    color: black;
  }

  input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0%;
  }


  .show_chosen_image_first {
    height: 65px;
    border-radius: 8px;
    width: 65px;
    object-fit: contain;
    /* position: absolute; */
    background-color: white;
  }



}

@media (min-width:450px ){



.multiple-image-upload {
  width: 99%;
  /* padding: 3%; */
  margin-left: 5%;
  height: 134px;
  height: 234px;
  /* margin-top: 3%; */
  border-radius: 18px;
  /* background-color: white; */
  /* box-shadow: 0 3px 30px #0000001a; */
}

.multiple-image-upload__header {
  margin-left: 4%;
  display: flex;
  font-size: 20px;
  padding-top: 0;
}

.multiple-image-upload__headline {
  width: 270px;
}

.multiple-image-upload__info-box-trigger {
  height: 20px;
  width: 20px;
  margin-top: 0.5%;
}

.multiple-image-upload__info-box-content {
  position: absolute;
  height: 90px;
  width: 380px;
  margin-left: 22%;
  margin-top: 0;
  padding: 0.5%;
  z-index: 3;
  /* box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25); */
  color: black;
  background-color: white;
  font-size: 12px;
}

.multiple-image-upload__gallery {
  align-items: center;
  gap: 2rem;
}

.multiple-image-upload__images-to-upload {
  display: flex;
  flex-direction: row-reverse;
  gap: 1.25rem;
  text-align: left;
  margin: 0 auto;

}

.multiple-image-upload__image-to-upload {
  height: 70px;
  width: 70px;
  position: relative;
}

.multiple-image-upload__new-image-x-icon-container {
  position: absolute;
  background: white;
  padding: 0 3px;
  border-radius: 25px;
  right: 0;
  border: 1px solid grey;
  font-size: 12px;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.multiple-image-upload__new-image-x-icon {
  color: black;
}

.multiple-image-upload__gallery-image {
  margin-left: 1%;
}

.multiple-image-upload__gallery-image-x-icon {
  color: black;
  margin-left: 4%;
  margin-top: 0.5%;
  z-index: 3;
  position: absolute;
}

.addphoto_camera_gallery {
  height: 40px;
  width: 65px;
  margin-left: 2%;
  margin-top: 0%;
  cursor: pointer;
  /* background-color: #33D075; */
  color: black;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0%;
}


.show_chosen_image_first {
  height: 70px;
  border-radius: 8px;
  width: 70px;
  object-fit: contain;
  /* position: absolute; */
  background-color: white;
}


}
</style>
