<template lang="html">
  <div class="">
    <div :style="{height:'24px',marginLeft:'4%',marginTop:'2%', display:'flex'}">
      <b-icon icon="vector-pen" :style="{height:'24px',width:'24px', color:'#0A6338' }" />
      <div :style="{height:'100%',fontSize: '16px',fontStyle: 'normal',lineHeight: '27px'}">
        Berätta om problemet
        <!-- Berätta om det du vill sälja -->
      </div>
    </div>

    <div :style="{borderWidth:'1px', borderStyle:'solid', borderColor:'black', width:'90%', marginLeft:'5%'}">
    </div>
    <div :style="{height:'10px'}">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
