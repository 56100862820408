<template>
  <div id="app">
    <!-- <div  v-if="this.w <=450" :style="{width:'100%', height:'66px', backgroundColor:'white'}">
      <Navigation/>
    </div> -->

    <div  class="Navigation_small">
      <Navigation/>
    </div>


    <div  class="Navigation_large">
      <Navigation/>
    </div>

       <router-view  :key="[$route.fullPath, value]" @routerViewReload="reload"/>

  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
export default {
  name: 'App',

  data() {
    return {
      value: 0,
    }
  },
  methods:{
    reload() {
      this.value++;
    }
  },

  components:{
   Navigation
  }
}

</script>

<style>
@media (max-width:450px ){

  .Navigation_large{
      display: None
  }
  .Navigation_small{
    width:100%;
    height:66px
  }

}

@media (min-width:450px ){

  .Navigation_small{
      display: None
  }
  .Navigation_large{
    width:100%;
    height:96px
  }

}


*{
  margin:0;
  font-family: sans-serif;
}

@font-face {
  font-family: sans-serif;
}

/* src:
  local(Avenir, Helvetica, Avenir, Helvetica, Arial,  Arial, sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-display: swap;

   */

#app {
  font-family:  sans-serif;
  text-align: center;

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
