<template lang="html">
  <div class="">
    <div :style="{ width:'95%', marginLeft:'5%'}">
      <div :style="{height:'24px',marginLeft:'0%',marginTop:'2%', display:'flex'}">
        <b-icon icon="cursor-fill" :style="{height:'24px',width:'24px', color:'#0A6338' }" />
        <div :style="{height:'100%',fontSize: '16px',fontStyle: 'normal',lineHeight: '27px'}">
          Samtyck, kolla info och skicka
        </div>
      </div>
    </div>
    <div :style="{borderWidth:'1px', borderStyle:'solid', borderColor:'black', width:'90%', marginLeft:'5%'}">
    </div>

  </div>

</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
