<template lang="html">
  <div class="">
    <div class="main">
      Titta igenom formuläret så uppgifterna stämmer.
      Vi kommer höra av oss inom 2 arbetsdagar
    </div>

  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>

@media (min-width: 450px) {
    .main{
      width:86%;
      margin-top: 0%;
      margin-left: 7%;
      height:30px;
    }
}


@media (max-width: 450px) {

  .main{
    width:96%;
    margin-left: 4%;
    height:90px;
  }

}

</style>
