import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/secret_santa',
    name: 'secret_santa',
    component: () => import(/* webpackChunkName: "mainPage" */ '../views/christmas1.vue')
  },
  {
    path: '/secret_santa2',
    name: 'christmas2',
    component: () => import(/* webpackChunkName: "mainPage" */ '../views/christmas2.vue')
  },

  {
    path: '/',
    name: 'explore_page',
    component: () => import(/* webpackChunkName: "mainPage" */ '../views/Explore_page.vue')
  },
  {
    path: '/see_all_leads',
    name: 'see_all_leads',
    component: () => import(/* webpackChunkName: "seeAllLeads" */ '../views/see_all_leads.vue')
  },
  {
    path: '/update_profile',
    name: 'update_profile',
    component: () => import(/* webpackChunkName: "updateProfile" */ '../views/update_profile.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/company_page',
    name: 'company_page',
    component: () => import(/* webpackChunkName: "company" */ '../views/company_page.vue')
  },
  {
    path: '/create_user',
    name: 'create_user',
    component: () => import(/* webpackChunkName: "createUser" */ '../views/create_user.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "analytic" */ '../views/analytics.vue')
  },
  {
    path: '/result_page',
    name: 'result_page',
    component: () => import(/* webpackChunkName: "resultPage" */ '../views/resultpage.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "resultPage" */ '../views/news.vue')
  },

  {
    path: '/guide',
    name: 'guide',
    component: () => import(/* webpackChunkName: "guide" */ '../views/guide.vue')
  },
  {
    path: '/guide/:category',
    name: 'guidesub',
    component: () => import(/* webpackChunkName: "guide_subcategory" */ '../views/guide_subcategory.vue'),
  },
  {
    path: '/guide/:category/:subcategory',
    name: 'guidesubsub',
    component: () => import(/* webpackChunkName: "guide_subsubcategory" */ '../views/guide_subsubcategory.vue'),
  },
  {
    path: '/login_signup',
    name: 'login_signup',
    component: () => import(/* webpackChunkName: "login" */ '../views/login_signup.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
  },
  {
    path: '/nykategori',
    name: 'addcategory',
    component: () => import(/* webpackChunkName: "addCategory" */ '../views/addcategory.vue')
  },
  {
    path: '/artiklar',
    name: 'artiklar',
    component: () => import(/* webpackChunkName: "Articles" */ '../views/articles.vue')
  },
  {
    path: '/search_page',
    name: 'search_page',
    component: () => import(/* webpackChunkName: "searchPage" */ '../views/search_page.vue')
  },
  {
    path: '/contact_request_flow',
    name: 'contact_request_flow',
    component: () => import(/* webpackChunkName: "contactRequestFlow" */ '../views/contact_request_flow.vue')
  },


  {
  path: '/profile',
  name: 'profile',
  component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue'),
  beforeEnter(to, from, next) {
  let user = window.localStorage.current_user

  if (user == undefined || store.state.current_user.user_id ==0){  //fixa så att det funkar att klicka logga ut
    next("/")
  }
  else{
    next()
  }
 }

},

]

const router = new VueRouter({
    mode:'history',
  routes
})

export default router
