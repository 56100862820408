import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {func} from './GetImageFunc.js'
Vue.prototype.$func = func
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Echarts from 'vue-echarts';

import 'echarts/lib/chart/bar';
//import "@vue/composition-api";
import 'echarts/lib/component/title';
Vue.component('chart', Echarts);
import "echarts";

//import 'echarts/lib/component/tooltip'

//import "@/plugins/echarts";

import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import VueSimpleAlert from "vue-simple-alert";

//import './registerServiceWorker'
Vue.use(VueSimpleAlert);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

export const bus = new Vue();
Vue.mixin({
  methods: {
    get_AWS: function () {  //används för getSignedUrl
      var AWS = require('aws-sdk');
        AWS.config.update({accessKeyId:process.env.VUE_APP_AWS_ACCESS_KEY ,
                            secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
                            region: 'eu-north-1'});
        var s3 = new AWS.S3();
        return s3
    },
}
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
