export const func = {
  get_image: (data) => {
    const AWS = require('aws-sdk')
    const myBucket = 'garemotest1'
    const myKey = data
    const signedUrlExpireSeconds = 3600 * 1

    const s3 = new AWS.S3({
      accessKeyId:  process.env.VUE_APP_AWS_ACCESS_KEY,
      signatureVersion: 'v4',
      region: 'eu-north-1',
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY
    });

    const url = s3.getSignedUrl('getObject', {
      Bucket: myBucket,
      Key: myKey,
      Expires: signedUrlExpireSeconds
    })
    return url
  }
}
